import axios from 'axios';

const authEndpoint = 'https://accounts.spotify.com/authorize';
const tokenEndpoint = 'https://accounts.spotify.com/api/token';
const clientId = import.meta.env.VITE_SPOTIFY_CLIENT_ID;
const redirectUri = import.meta.env.VITE_SPOTIFY_REDIRECT_URI;
const scopes = [
  'user-read-currently-playing',
  'user-read-playback-state',
];

export const loginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes.join(' '))}&response_type=token&show_dialog=true`;

export const getTokenFromUrl = () => {
  return window.location.hash
    .substring(1)
    .split('&')
    .reduce((initial: any, item) => {
      let parts = item.split('=');
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getCurrentTrack = async (accessToken: string, retryCount = 0): Promise<any> => {
  try {
    const response = await axios.get('https://api.spotify.com/v1/me/player/currently-playing', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 429 && retryCount < 3) {
      const retryAfter = error.response.headers['retry-after'] || 1;
      await wait(retryAfter * 1000);
      return getCurrentTrack(accessToken, retryCount + 1);
    }
    console.error('Error fetching current track:', error);
    throw error;
  }
};