import React, { useState } from 'react';
import { signInWithPopup, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { LogIn } from 'lucide-react';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.code === 'auth/popup-blocked') {
        try {
          // Fallback to redirect method
          await signInWithRedirect(auth, provider);
          // The page will reload after redirect, so no need to navigate
        } catch (redirectError: any) {
          setError(`Error signing in: ${redirectError.message}`);
        }
      } else if (error.code === 'auth/unauthorized-domain') {
        setError('This domain is not authorized for authentication. Please contact the administrator.');
      } else {
        setError(`Error signing in: ${error.message}`);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-center">Login to Spotify Chat</h2>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <button
          onClick={handleLogin}
          className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300 flex items-center justify-center mb-4"
        >
          <LogIn className="mr-2" />
          Sign in with Google
        </button>
        <p className="text-center text-sm text-gray-600">
          You can connect your Spotify account after logging in
        </p>
      </div>
    </div>
  );
};

export default Login;