import React from 'react';
import { Link } from 'react-router-dom';
import { Music } from 'lucide-react';

const Home: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="text-center">
        <Music className="w-24 h-24 text-white mb-8 mx-auto" />
        <h1 className="text-5xl font-bold text-white mb-4">Spotify Chat</h1>
        <p className="text-xl text-white mb-8">Connect with others listening to the same song!</p>
        <Link
          to="/login"
          className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full hover:bg-opacity-90 transition duration-300"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default Home;