import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getCurrentTrack } from '../utils/spotify';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, addDoc, serverTimestamp, doc, setDoc, getDocs, orderBy, deleteDoc } from 'firebase/firestore';
import { Music, Send, RefreshCw } from 'lucide-react';
import ProfileMenu from './ProfileMenu';

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const [currentTrack, setCurrentTrack] = useState<any | null>(null);
  const [chatPartner, setChatPartner] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [spotifyConnected, setSpotifyConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [chatRoomId, setChatRoomId] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const lastFetchRef = useRef<number>(0);

  const deleteChatRoomMessages = useCallback(async (roomId: string) => {
    const messagesRef = collection(db, `chatRooms/${roomId}/messages`);
    const snapshot = await getDocs(messagesRef);
    const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
  }, []);

  const fetchCurrentTrack = useCallback(async () => {
    const token = localStorage.getItem('spotify_token');
    if (!token) {
      setSpotifyConnected(false);
      return;
    }

    const now = Date.now();
    if (now - lastFetchRef.current < 5000) {
      console.log('Skipping fetch due to rate limit');
      return;
    }

    try {
      setIsRefreshing(true);
      const track = await getCurrentTrack(token);
      setIsRefreshing(false);
      lastFetchRef.current = now;

      if (track?.item && currentUser) {
        if (currentTrack && currentTrack.id !== track.item.id) {
          // Song changed, delete messages
          if (chatRoomId) {
            await deleteChatRoomMessages(chatRoomId);
          }
        }
        setCurrentTrack(track.item);
        await setDoc(doc(db, 'users', currentUser.uid), {
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          currentTrack: {
            id: track.item.id,
            name: track.item.name,
            artist: track.item.artists[0].name,
          },
          lastUpdated: serverTimestamp(),
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error fetching current track', error);
      setError('Failed to fetch current track');
      setIsRefreshing(false);
      if ((error as any).response?.status === 401) {
        localStorage.removeItem('spotify_token');
        setSpotifyConnected(false);
      }
    }
  }, [currentUser, currentTrack, chatRoomId, deleteChatRoomMessages]);

  useEffect(() => {
    const token = localStorage.getItem('spotify_token');
    if (token) {
      setSpotifyConnected(true);
      fetchCurrentTrack();
      const interval = setInterval(fetchCurrentTrack, 30000); // Fetch every 30 seconds
      return () => clearInterval(interval);
    } else {
      setSpotifyConnected(false);
    }
  }, [fetchCurrentTrack]);

  useEffect(() => {
    if (currentTrack && spotifyConnected && currentUser) {
      const q = query(
        collection(db, 'users'),
        where('currentTrack.id', '==', currentTrack.id),
        where('uid', '!=', currentUser.uid)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const partner = snapshot.docs[0].data();
          setChatPartner(partner.displayName);
          const roomId = [currentUser.uid, partner.uid].sort().join('_');
          setChatRoomId(roomId);
        } else {
          setChatPartner(null);
          setChatRoomId(null);
          setMessages([]);
        }
      }, (err) => {
        console.error("Error fetching chat partner:", err);
        setError('Failed to fetch chat partner');
      });

      return () => unsubscribe();
    }
  }, [currentTrack, currentUser, spotifyConnected]);

  useEffect(() => {
    if (chatRoomId) {
      const q = query(
        collection(db, `chatRooms/${chatRoomId}/messages`),
        orderBy('createdAt', 'asc')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(fetchedMessages);
      }, (err) => {
        console.error("Error fetching messages:", err);
        setError('Failed to fetch messages');
      });

      return () => unsubscribe();
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim() && currentTrack && currentUser && chatRoomId) {
      try {
        await addDoc(collection(db, `chatRooms/${chatRoomId}/messages`), {
          text: newMessage,
          createdAt: serverTimestamp(),
          uid: currentUser.uid,
          displayName: currentUser.displayName,
        });
        setNewMessage('');
      } catch (err) {
        console.error("Error sending message:", err);
        setError('Failed to send message');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-green-600 text-white p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold flex items-center">
          <Music className="mr-2" /> Spotify Chat
        </h1>
        <ProfileMenu />
      </header>
      <main className="flex-grow flex flex-col p-4 gap-4 overflow-hidden">
        {error && (
          <div className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="w-full bg-white rounded-lg shadow p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Now Playing</h2>
            <button
              onClick={fetchCurrentTrack}
              disabled={isRefreshing}
              className="bg-green-500 text-white px-3 py-1 rounded-full hover:bg-green-600 transition duration-300 flex items-center disabled:opacity-50"
            >
              <RefreshCw className={`mr-1 h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              Refresh
            </button>
          </div>
          {spotifyConnected ? (
            currentTrack ? (
              <div className="flex items-center">
                <img
                  src={currentTrack.album.images[0].url}
                  alt={currentTrack.name}
                  className="w-24 h-24 rounded-lg mr-4"
                />
                <div>
                  <p className="font-bold">{currentTrack.name}</p>
                  <p>{currentTrack.artists.map((artist: any) => artist.name).join(', ')}</p>
                </div>
              </div>
            ) : (
              <p>No track currently playing</p>
            )
          ) : (
            <p>Connect Spotify to see current track</p>
          )}
        </div>
        {spotifyConnected && (
          <div className="flex-grow flex flex-col overflow-hidden">
            <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col h-[400px]">
              <h2 className="text-xl font-semibold mb-4">
                {chatPartner ? `Chatting with ${chatPartner}` : 'No one else is listening to this track'}
              </h2>
              <div className="flex-grow overflow-y-auto mb-4">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`mb-2 ${
                      message.uid === currentUser?.uid ? 'text-right' : 'text-left'
                    }`}
                  >
                    <span
                      className={`inline-block px-4 py-2 rounded-lg ${
                        message.uid === currentUser?.uid
                          ? 'bg-green-500 text-white'
                          : 'bg-gray-300 text-black'
                      }`}
                    >
                      {message.text}
                    </span>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <form onSubmit={sendMessage} className="flex mt-auto">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                  className="flex-grow border rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
                  disabled={!chatPartner}
                />
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded-r-lg hover:bg-green-600 transition duration-300 flex items-center disabled:opacity-50"
                  disabled={!chatPartner}
                >
                  <Send className="mr-2" /> Send
                </button>
              </form>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Dashboard;