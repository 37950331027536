import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromUrl } from '../utils/spotify';
import { useAuth } from '../contexts/AuthContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const SpotifyCallback: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthChecked(true);
      if (user) {
        const token = getTokenFromUrl().access_token;
        if (token) {
          localStorage.setItem('spotify_token', token);
          navigate('/dashboard');
        } else {
          console.error('No token found in URL');
          navigate('/dashboard');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!authChecked) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <p className="text-xl">Connecting to Spotify...</p>
      </div>
    );
  }

  return null;
};

export default SpotifyCallback;