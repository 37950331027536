import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, LogOut, Music } from 'lucide-react';
import { auth } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { loginUrl } from '../utils/spotify';

const ProfileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [spotifyConnected, setSpotifyConnected] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('spotify_token');
    setSpotifyConnected(!!token);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem('spotify_token');
    navigate('/');
  };

  const handleSpotifyConnect = () => {
    // Instead of opening a new window, we'll navigate to the Spotify login URL
    window.location.href = loginUrl;
  };

  const handleSpotifyDisconnect = () => {
    localStorage.removeItem('spotify_token');
    setSpotifyConnected(false);
    window.location.reload();
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 bg-white rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        <User className="h-6 w-6 text-gray-600" />
        <span className="text-gray-600">{currentUser?.displayName}</span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
          {!spotifyConnected ? (
            <button
              onClick={handleSpotifyConnect}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center"
            >
              <Music className="mr-2 h-4 w-4" />
              Connect Spotify
            </button>
          ) : (
            <button
              onClick={handleSpotifyDisconnect}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center"
            >
              <Music className="mr-2 h-4 w-4" />
              Disconnect Spotify
            </button>
          )}
          <button
            onClick={handleLogout}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center"
          >
            <LogOut className="mr-2 h-4 w-4" />
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;